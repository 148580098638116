import { Link, useLoaderData } from '@remix-run/react'
import { json, redirect, type LoaderFunctionArgs } from '@vercel/remix'
import { $path } from 'remix-routes'
import { Button, Heading } from '~/components/ui'
import { NewsIndex } from '~/features/news/components/NewsIndex'
import { fetchNewsIndex } from '~/features/news/hooks/useNews'
import { StatsPanel } from '~/routes/resources+/stats'
import { getUserAndProfile } from '~/services/auth.server'

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const { user, profile } = await getUserAndProfile(request)
  if (user && !profile) {
    // ログインしたがプロフィールがない場合はプロフィール登録画面に飛ばす
    return redirect('/register')
  }
  const newsIndex = await fetchNewsIndex(100)
  return json({ user, newsIndex })
}

const MainAction = ({ isLoggedIn }: { isLoggedIn: boolean }) => {
  if (isLoggedIn) {
    return (
      <Button asChild className="mt-10 w-full px-10" size="xl" variant="default">
        <Link to={$path('/lookup')}>検索する</Link>
      </Button>
    )
  }

  return (
    <div className="mx-auto mt-10 grid w-full grid-cols-1 place-items-center gap-3 md:grid-cols-2 md:gap-5">
      <Button asChild size="xl" className="w-full" variant="default">
        <Link to={$path('/signup')}>無料ユーザ登録</Link>
      </Button>

      <Button asChild size="xl" variant="outline" className="w-full border-primary text-primary hover:text-primary">
        <Link to={$path('/signin')}>サインイン</Link>
      </Button>
    </div>
  )
}

export default function HomePage() {
  const { user, newsIndex } = useLoaderData<typeof loader>()

  return (
    <section>
      <div className="mx-auto max-w-3xl px-0 py-16 text-center sm:py-20 lg:px-8">
        <Heading className="my-4 text-4xl font-extrabold leading-none tracking-normal sm:text-6xl md:text-6xl">
          <span className="whitespace-nowrap text-blue-600">化学防護手袋</span>
          <span>の</span>
          <span className="whitespace-nowrap bg-gradient-to-b from-transparent from-70% via-yellow-300 via-70% to-yellow-300">
            薬品耐透過性を検索
          </span>
        </Heading>

        <p className="mx-auto max-w-xl text-lg">調達前に安全性を確認。</p>

        <MainAction isLoggedIn={!!user} />

        <NewsIndex items={newsIndex} limit={1} className="mt-8" />
      </div>

      <StatsPanel />
    </section>
  )
}
